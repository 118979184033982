import { useQuery, UseQueryOptions } from 'react-query';

import { AppService } from 'services/app';
import { StoreProduct } from 'models/store-product';

export function useGetStoreProducts(
  storeId: string,
  options?: UseQueryOptions<StoreProduct[], Error>
) {
  return useQuery<StoreProduct[], Error>(
    ['storeProducts', storeId],
    () => AppService.getStoreProducts(storeId),
    options
  );
}
