import { axios } from 'config/axios';

import { Store } from 'models/store';
import { StoreProduct } from 'models/store-product';

export class AppService {
  static async get(): Promise<Store[]> {
    const { data } = await axios.get('stores');
    return data;
  }
  static async getStoreProducts(storeId: string): Promise<StoreProduct[]> {
    const { data } = await axios.get(`stores/${storeId}/products`);
    return data;
  }
}
