import React from 'react';

type State = {
  isLoggedIn: boolean;
  token?: string;
};

export const AuthContext = React.createContext<
  [State, React.Dispatch<React.SetStateAction<State>>] | undefined
>(undefined);
AuthContext.displayName = 'AuthContext';

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const token = localStorage.getItem('loginToken') ?? undefined;
  const value = React.useState<State>({ isLoggedIn: !!token, token });
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
