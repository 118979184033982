import React from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { SelectStore } from './SelectStore';
import { Menu, menuWidth } from './Menu';
import { Account } from './Account';

export const topBarHeight = '60px';

export function Navbar({ children }: { children?: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <>
      <AppBar
        sx={{
          width: {
            sm: `calc(100% - ${menuWidth}px)`,
          },
          height: topBarHeight,
          backgroundColor: 'white',
        }}
        position="fixed"
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          variant="dense"
        >
          <IconButton
            sx={{
              display: {
                sm: 'none',
              },
              marginRight: (theme) => theme.spacing(2),
            }}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MenuIcon />
          </IconButton>
          {children}
          <SelectStore />
          <Box>
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}
