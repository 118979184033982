import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useGetStores } from 'features/store/hooks/useGetStores';
import { useStore } from 'App/provider';
import { menuWidth } from './Menu';

export function SelectStore() {
  const [store, setStore] = useStore();
  const { isLoading, data: stores } = useGetStores();

  if (isLoading) {
    <CircularProgress />;
  }

  return (
    <FormControl
      sx={{
        minWidth: 130,
        ml: menuWidth,
        mt: 2,
      }}
    >
      <InputLabel>Store</InputLabel>
      <Select
        sx={{
          height: 40,
        }}
        fullWidth
        value={store.storeId}
        label="Store"
        onChange={(event: SelectChangeEvent) => {
          const selectedStore = stores?.find(
            (store) => store._id === event.target.value
          )!;
          setStore({
            storeId: selectedStore._id,
          });
        }}
      >
        {stores?.map(({ _id: storeId, name: storeName }) => (
          <MenuItem key={storeId} value={storeId}>
            {storeName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
