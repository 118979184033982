import { createTheme } from '@mui/material';
import { orange } from '@mui/material/colors';
import type {} from '@mui/lab/themeAugmentation';

export default createTheme({
  palette: {
    // NOTE: We will define a better palette when we have more features
    // primary: {
    //   main: orange[500],
    //   light: '#ffd95b',
    //   dark: '#c77800',
    //   contrastText: grey[900],
    // },
    primary: orange,
  },
  // NOTE: We will define a better palette when we have more features
  // components: {
  //   MuiAppBar: {
  //     styleOverrides: {
  //       colorPrimary: {
  //         color: grey[900],
  //         backgroundColor: grey[100],
  //       },
  //     },
  //   },
  //   MuiDrawer: {
  //     styleOverrides: {
  //       paperAnchorLeft: {
  //         color: 'white',
  //         backgroundColor: 'white',
  //       },
  //     },
  //   },
  //   MuiSvgIcon: {
  //     styleOverrides: {
  //       colorPrimary: {
  //         color: 'white',
  //       },
  //     },
  //   },
  // },
});
