import React from 'react';
import { Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import { CircularProgress, ThemeProvider } from '@mui/material';

import { AuthProvider, useAuth } from 'features/auth/provider';

import App from 'App';
import theme from 'theme';

// pages
const LoginPage = React.lazy(() => import('features/auth/Login'));
const LogoutPage = React.lazy(() => import('features/auth/LogoutPage'));
const DashboardPage = React.lazy(() => import('features/dashboard/Dashboard'));

const PurchaseForm = React.lazy(() => import('features/purchase/PurchaseForm'));
const Purchase = React.lazy(() => import('features/purchase/Purchase'));
const TransferForm = React.lazy(() => import('features/transfer/TransferForm'));
const Transfer = React.lazy(() => import('features/transfer/Transfer'));
const StoreForm = React.lazy(() => import('features/store/StoreForm'));
const Store = React.lazy(() => import('features/store/Store'));
const ProductForm = React.lazy(() => import('features/product/ProductForm'));
const Product = React.lazy(() => import('features/product/Product'));
const EmployeeForm = React.lazy(() => import('features/employee/EmployeeForm'));
const Employee = React.lazy(() => import('features/employee/Employee'));

type RouteConfigProps = RouteProps & {
  component: React.LazyExoticComponent<React.ComponentType>;
  useAuth?: boolean;
};

const AuthRoute: React.FunctionComponent<RouteConfigProps> = ({
  component: Component,
  ...rest
}) => {
  const [auth] = useAuth();
  if (!auth.isLoggedIn) {
    debugger;
    return <Redirect to="/login" />;
  }
  return (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  );
};

const routes: RouteConfigProps[] = [
  {
    path: '/',
    exact: true,
    component: DashboardPage,
    useAuth: true,
  },
  {
    path: '/dashboard',
    exact: true,
    component: DashboardPage,
    useAuth: true,
  },
  {
    path: '/login',
    exact: true,
    component: LoginPage,
  },
  {
    path: '/logout',
    exact: true,
    component: LogoutPage,
  },
  {
    path: '/purchases/create',
    exact: true,
    useAuth: true,
    component: PurchaseForm,
  },
  {
    path: '/purchases',
    exact: true,
    // useAuth: true,
    component: Purchase,
  },
  {
    path: '/transfers/create',
    exact: true,
    useAuth: true,
    component: TransferForm,
  },
  {
    path: '/transfers',
    exact: true,
    useAuth: true,
    component: Transfer,
  },
  {
    path: '/stores/create',
    exact: true,
    useAuth: true,
    component: StoreForm,
  },
  {
    path: '/stores',
    exact: true,
    useAuth: true,
    component: Store,
  },
  {
    path: '/products/create',
    exact: true,
    useAuth: true,
    component: ProductForm,
  },
  {
    path: '/products',
    exact: true,
    useAuth: true,
    component: Product,
  },
  {
    path: '/employees/create',
    exact: true,
    useAuth: true,
    component: EmployeeForm,
  },
  {
    path: '/employees',
    exact: true,
    useAuth: true,
    component: Employee,
  },
];

const RootRouter = () => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <App>
        <React.Suspense fallback={<CircularProgress />}>
          <Switch>
            {routes.map(({ useAuth, ...authProps }, index) => {
              if (useAuth) {
                return <AuthRoute key={index} {...authProps} />;
              }
              return <Route key={index} {...authProps} />;
            })}
          </Switch>
        </React.Suspense>
      </App>
    </AuthProvider>
  </ThemeProvider>
);

export default RootRouter;
