import React from 'react';
import { Container } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import theme from 'theme';
import { useGetStores } from '../features/store/hooks/useGetStores';
import { useAuth } from 'features/auth/provider';

import { Navbar, menuWidth, topBarHeight } from './components';
import { useGetStoreProducts } from './hooks/useGetStoreProducts';
import { StoreProvider } from 'App/provider';

const App: React.FunctionComponent = ({ children }) => {
  const [auth] = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const { data: stores, isLoading: isLoadingStores } = useGetStores({
    enabled: auth.isLoggedIn,
  });

  const defaultStore = stores?.[0]?._id;
  const { isLoading: isLoadingStoreProducts } = useGetStoreProducts(
    defaultStore!,
    {
      enabled: !!defaultStore,
    }
  );

  if (!auth.isLoggedIn) {
    return <div>{children}</div>;
  }

  if (isLoadingStores || isLoadingStoreProducts) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StoreProvider storeId={defaultStore}>
      <Navbar />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: topBarHeight,
          ml: isMobile ? undefined : menuWidth,
        }}
        fixed
      >
        <Box sx={{ mt: 4 }}>{children}</Box>
      </Container>
    </StoreProvider>
  );
};

export default App;
