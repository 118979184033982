import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Button } from '@mui/material';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';

export function Account() {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        color="inherit"
      >
        <AccountCircleOutlined />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          <Button
            onClick={() => {
              history.replace('/logout');
            }}
          >
            {' '}
            Cerrar Sesión{' '}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
