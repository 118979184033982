import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { useState } from 'react';

export type MenuItemProps = {
  title: string;
  icon?: React.ReactNode;
  url: string;
  subMenus?: MenuItemProps[];
};

export function MenuItem({ title, icon, url, subMenus }: MenuItemProps) {
  const location = useLocation();
  const history = useHistory();
  const [showSubmenus, setShowSubmenus] = useState(
    subMenus?.some(({ url }) => url === location.pathname)
  );

  return (
    <>
      <ListItemButton>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemButton
          onClick={() => {
            setShowSubmenus(!showSubmenus);
          }}
        >
          <ListItemText primary={title} />
          {showSubmenus ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItemButton>
      <Collapse in={showSubmenus} timeout="auto" unmountOnExit>
        <List>
          {subMenus?.map(({ title, url }: any) => (
            <ListItemButton
              key={title}
              sx={{
                pl: 12,
              }}
              selected={url === location.pathname}
              onClick={() => history.replace(url)}
            >
              <ListItemText primary={title} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}
