import React from 'react';

type State = {
  storeId?: string;
};

export const StoreContext = React.createContext<
  [State, React.Dispatch<React.SetStateAction<State>>] | undefined
>(undefined);
StoreContext.displayName = 'StoreContext';

export function StoreProvider({
  children,
  storeId,
}: {
  children: React.ReactNode;
  storeId?: string;
}) {
  const value = React.useState<State>({ storeId });
  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}

export function useStore() {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
}
