import axios from 'axios';
import { apiBaseUrl } from './config';

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('loginToken');
  if (token) {
    config.headers['Content-Type'] = 'application/json';
    config.headers.Authorization = token;
  }
  return config;
});

export { axiosInstance as axios };
