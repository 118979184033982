import { Box, Drawer, Divider, List, useMediaQuery } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';

import { styled } from '@mui/system';

import theme from 'theme';

import { MenuItem, MenuItemProps } from './MenuItem';

export const menuWidth = '250px';

const menus: MenuItemProps[] = [
  {
    title: 'Purchases',
    icon: <WorkIcon color="primary" />,
    url: '/purchases',
    subMenus: [
      {
        title: 'Create',
        url: '/purchases/create',
      },
      {
        title: 'List',
        url: '/purchases',
      },
    ],
  },
  {
    title: 'Transfers',
    icon: <WorkIcon color="primary" />,
    url: '/transfers',
    subMenus: [
      {
        title: 'Create',
        url: '/transfers/create',
      },
      {
        title: 'List',
        url: '/transfers',
      },
    ],
  },
  {
    title: 'Stores',
    icon: <WorkIcon color="primary" />,
    url: '/stores',
    subMenus: [
      {
        title: 'Create',
        url: '/stores/create',
      },
      {
        title: 'List',
        url: '/stores',
      },
    ],
  },
  {
    title: 'Products',
    icon: <WorkIcon color="primary" />,
    url: '/products',
    subMenus: [
      {
        title: 'Create',
        url: '/products/create',
      },
      {
        title: 'List',
        url: '/products',
      },
    ],
  },
  {
    title: 'Employees',
    icon: <WorkIcon color="primary" />,
    url: '/employees',
    subMenus: [
      {
        title: 'Create',
        url: '/employees/create',
      },
      {
        title: 'List',
        url: '/employees',
      },
    ],
  },
];

const Logo = styled('img')({
  width: '40px',
  height: '40px',
  margin: '0 auto',
});

export function Menu({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: Function;
}) {
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <Logo src={`${process.env.PUBLIC_URL}/logo500.png`} alt="NM Logo" />
        <h3>Naranja Mecanica</h3>
      </Box>
      <Divider />
      <List
        sx={{
          width: {
            sm: menuWidth,
          },
        }}
      >
        {menus.map((menuItem, i) => (
          <MenuItem key={i} {...menuItem} />
        ))}
      </List>
    </Drawer>
  );
}
